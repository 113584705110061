// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  idCampaingPortal: 110344,
  idOrigen: 'SMS_CORTO',
  baseUrlSMS: 'http://mayten.cloud/auth',

  //baseUrl:'http://localhost:8086',
  baseUrl: 'https://staging.api.smartleads.marketing',
  //baseUrl: 'https://prestg.api.smartleads.marketing',

  //INFO to Validate EMAIL
  apikey: '432ce3da6e504e4eb3a48f553db07dfc',
  baseUrlEmail: 'https://api.zerobounce.net/v2/validate',
};
